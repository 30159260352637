import { default as aboutcggDDldG7iMeta } from "/vercel/path0/pages/about.vue?macro=true";
import { default as index5uIXihQniLMeta } from "/vercel/path0/pages/alternatives/index.vue?macro=true";
import { default as _91slug_93Po03rnzIFtMeta } from "/vercel/path0/pages/blog/[slug].vue?macro=true";
import { default as indexmfxAx2eWMBMeta } from "/vercel/path0/pages/blog/index.vue?macro=true";
import { default as contactJnsDpYVejzMeta } from "/vercel/path0/pages/contact.vue?macro=true";
import { default as featuresenv3YeqUT9Meta } from "/vercel/path0/pages/features.vue?macro=true";
import { default as indexe9Brt5DfdhMeta } from "/vercel/path0/pages/index.vue?macro=true";
import { default as lean_45canvasin5ZRbS2JRMeta } from "/vercel/path0/pages/lean-canvas.vue?macro=true";
import { default as _91slug_933IUKTQbiyHMeta } from "/vercel/path0/pages/people/[slug].vue?macro=true";
import { default as index3McTQJxm2fMeta } from "/vercel/path0/pages/people/index.vue?macro=true";
import { default as _91slug_93C7mNa6Br3BMeta } from "/vercel/path0/pages/pitches/[slug].vue?macro=true";
import { default as indexs0vZCJ17UMMeta } from "/vercel/path0/pages/pitches/index.vue?macro=true";
import { default as portfolioek2jrdhiX7Meta } from "/vercel/path0/pages/portfolio.vue?macro=true";
import { default as indexkd184pULVkMeta } from "/vercel/path0/pages/services/index.vue?macro=true";
import { default as _91slug_936UQt1MS3roMeta } from "/vercel/path0/pages/tools/[slug].vue?macro=true";
import { default as index4j2rQXmZFiMeta } from "/vercel/path0/pages/tools/index.vue?macro=true";
import { default as value_45based_45pricing_45canvasqivVEZi2QEMeta } from "/vercel/path0/pages/value-based-pricing-canvas.vue?macro=true";
export default [
  {
    name: aboutcggDDldG7iMeta?.name ?? "about___en",
    path: aboutcggDDldG7iMeta?.path ?? "/about",
    meta: aboutcggDDldG7iMeta || {},
    alias: aboutcggDDldG7iMeta?.alias || [],
    redirect: aboutcggDDldG7iMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/about.vue").then(m => m.default || m)
  },
  {
    name: index5uIXihQniLMeta?.name ?? "alternatives___en",
    path: index5uIXihQniLMeta?.path ?? "/alternatives",
    meta: index5uIXihQniLMeta || {},
    alias: index5uIXihQniLMeta?.alias || [],
    redirect: index5uIXihQniLMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/alternatives/index.vue").then(m => m.default || m)
  },
  {
    name: _91slug_93Po03rnzIFtMeta?.name ?? "blog-slug___en",
    path: _91slug_93Po03rnzIFtMeta?.path ?? "/blog/:slug()",
    meta: _91slug_93Po03rnzIFtMeta || {},
    alias: _91slug_93Po03rnzIFtMeta?.alias || [],
    redirect: _91slug_93Po03rnzIFtMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/blog/[slug].vue").then(m => m.default || m)
  },
  {
    name: indexmfxAx2eWMBMeta?.name ?? "blog___en",
    path: indexmfxAx2eWMBMeta?.path ?? "/blog",
    meta: indexmfxAx2eWMBMeta || {},
    alias: indexmfxAx2eWMBMeta?.alias || [],
    redirect: indexmfxAx2eWMBMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/blog/index.vue").then(m => m.default || m)
  },
  {
    name: contactJnsDpYVejzMeta?.name ?? "contact___en",
    path: contactJnsDpYVejzMeta?.path ?? "/contact",
    meta: contactJnsDpYVejzMeta || {},
    alias: contactJnsDpYVejzMeta?.alias || [],
    redirect: contactJnsDpYVejzMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/contact.vue").then(m => m.default || m)
  },
  {
    name: featuresenv3YeqUT9Meta?.name ?? "features___en",
    path: featuresenv3YeqUT9Meta?.path ?? "/features",
    meta: featuresenv3YeqUT9Meta || {},
    alias: featuresenv3YeqUT9Meta?.alias || [],
    redirect: featuresenv3YeqUT9Meta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/features.vue").then(m => m.default || m)
  },
  {
    name: indexe9Brt5DfdhMeta?.name ?? "index___en",
    path: indexe9Brt5DfdhMeta?.path ?? "/",
    meta: indexe9Brt5DfdhMeta || {},
    alias: indexe9Brt5DfdhMeta?.alias || [],
    redirect: indexe9Brt5DfdhMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/index.vue").then(m => m.default || m)
  },
  {
    name: lean_45canvasin5ZRbS2JRMeta?.name ?? "lean-canvas___en",
    path: lean_45canvasin5ZRbS2JRMeta?.path ?? "/lean-canvas",
    meta: lean_45canvasin5ZRbS2JRMeta || {},
    alias: lean_45canvasin5ZRbS2JRMeta?.alias || [],
    redirect: lean_45canvasin5ZRbS2JRMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/lean-canvas.vue").then(m => m.default || m)
  },
  {
    name: _91slug_933IUKTQbiyHMeta?.name ?? "people-slug___en",
    path: _91slug_933IUKTQbiyHMeta?.path ?? "/people/:slug()",
    meta: _91slug_933IUKTQbiyHMeta || {},
    alias: _91slug_933IUKTQbiyHMeta?.alias || [],
    redirect: _91slug_933IUKTQbiyHMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/people/[slug].vue").then(m => m.default || m)
  },
  {
    name: index3McTQJxm2fMeta?.name ?? "people___en",
    path: index3McTQJxm2fMeta?.path ?? "/people",
    meta: index3McTQJxm2fMeta || {},
    alias: index3McTQJxm2fMeta?.alias || [],
    redirect: index3McTQJxm2fMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/people/index.vue").then(m => m.default || m)
  },
  {
    name: _91slug_93C7mNa6Br3BMeta?.name ?? "pitches-slug___en",
    path: _91slug_93C7mNa6Br3BMeta?.path ?? "/pitches/:slug()",
    meta: _91slug_93C7mNa6Br3BMeta || {},
    alias: _91slug_93C7mNa6Br3BMeta?.alias || [],
    redirect: _91slug_93C7mNa6Br3BMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/pitches/[slug].vue").then(m => m.default || m)
  },
  {
    name: indexs0vZCJ17UMMeta?.name ?? "pitches___en",
    path: indexs0vZCJ17UMMeta?.path ?? "/pitches",
    meta: indexs0vZCJ17UMMeta || {},
    alias: indexs0vZCJ17UMMeta?.alias || [],
    redirect: indexs0vZCJ17UMMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/pitches/index.vue").then(m => m.default || m)
  },
  {
    name: portfolioek2jrdhiX7Meta?.name ?? "portfolio___en",
    path: portfolioek2jrdhiX7Meta?.path ?? "/portfolio",
    meta: portfolioek2jrdhiX7Meta || {},
    alias: portfolioek2jrdhiX7Meta?.alias || [],
    redirect: portfolioek2jrdhiX7Meta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/portfolio.vue").then(m => m.default || m)
  },
  {
    name: indexkd184pULVkMeta?.name ?? "services___en",
    path: indexkd184pULVkMeta?.path ?? "/services",
    meta: indexkd184pULVkMeta || {},
    alias: indexkd184pULVkMeta?.alias || [],
    redirect: indexkd184pULVkMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/services/index.vue").then(m => m.default || m)
  },
  {
    name: _91slug_936UQt1MS3roMeta?.name ?? "tools-slug___en",
    path: _91slug_936UQt1MS3roMeta?.path ?? "/tools/:slug()",
    meta: _91slug_936UQt1MS3roMeta || {},
    alias: _91slug_936UQt1MS3roMeta?.alias || [],
    redirect: _91slug_936UQt1MS3roMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/tools/[slug].vue").then(m => m.default || m)
  },
  {
    name: index4j2rQXmZFiMeta?.name ?? "tools___en",
    path: index4j2rQXmZFiMeta?.path ?? "/tools",
    meta: index4j2rQXmZFiMeta || {},
    alias: index4j2rQXmZFiMeta?.alias || [],
    redirect: index4j2rQXmZFiMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/tools/index.vue").then(m => m.default || m)
  },
  {
    name: value_45based_45pricing_45canvasqivVEZi2QEMeta?.name ?? "value-based-pricing-canvas___en",
    path: value_45based_45pricing_45canvasqivVEZi2QEMeta?.path ?? "/value-based-pricing-canvas",
    meta: value_45based_45pricing_45canvasqivVEZi2QEMeta || {},
    alias: value_45based_45pricing_45canvasqivVEZi2QEMeta?.alias || [],
    redirect: value_45based_45pricing_45canvasqivVEZi2QEMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/value-based-pricing-canvas.vue").then(m => m.default || m)
  }
]